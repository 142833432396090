import { Box, Button, Card, CardContent, Grid, styled, Switch, Typography } from '@mui/material';
import React, { useState } from 'react';

const SubscriptionCardMui = styled(Card)`
  background-color: ${({ theme }) => theme.palette.primaryDark[600]};
  margin: auto;
`;

export const SubscriptionCard = () => {
  const [yearly, setYearly] = useState(false);
  const handleSubscription = async () => {
    window.location.href = 'https://app.pollpilot.io';
  };
  return (
    <>
      <Grid sx={{ m: 'auto', width: 'fit-content' }} container gap={1}>
        <Grid item sx={{ m: 'auto' }}>
          <Typography variant="body1">Monthly</Typography>
        </Grid>
        <Grid item>
          <Switch checked={yearly} onChange={(e) => setYearly(e.target.checked)} />
        </Grid>
        <Grid item sx={{ m: 'auto' }}>
          <Typography variant="body1">Yearly</Typography>
        </Grid>
      </Grid>
      <SubscriptionCardMui>
        <CardContent>
          <Box mb={2}>
            <Typography variant="h3" gutterBottom>
              Standard Subscription
            </Typography>
            <Typography textAlign="center" variant="h2">
              ${yearly ? 16 : 20}
              <Typography component="span" variant="body2">
                / month
              </Typography>
            </Typography>
          </Box>
          <Box mb={2} textAlign="center">
            <Typography variant="subtitle2">Unlimited widgets</Typography>
            <Typography variant="subtitle2">Unlimited responses</Typography>
            <Typography variant="subtitle2">Customer support</Typography>
            <Typography variant="subtitle2">Cancel anytime</Typography>
          </Box>
          <Grid container>
            <Button
              size="large"
              variant="contained"
              sx={{ m: 'auto' }}
              onClick={handleSubscription}
            >
              Start a 7 day free trial
            </Button>
          </Grid>
          <Typography sx={{ mt: 2 }} textAlign="center" variant="body2">
            No credit card required
          </Typography>
        </CardContent>
      </SubscriptionCardMui>
    </>
  );
};
