import GradientText from '@/components/GradientText';
import { Layout } from '@/components/Layout';
import { SubscriptionCard } from '@/components/SubscriptionCard';
import { Box, Container, Grid, Typography } from '@mui/material';
import React from 'react';

export default () => {
  return (
    <Layout>
      <Container>
        <Box mb={20} mt={20}>
          <Grid item container sx={{ m: 'auto', flexDirection: 'column' }} gap={4}>
            <Box sx={{ maxWidth: '600px', m: 'auto' }}>
              <Typography textAlign="center" variant="subtitle1" color="primary">
                SIMPLE PRICING
              </Typography>
              <Typography textAlign="center" variant="h1">
                One subscription for <GradientText>all your needs</GradientText>
              </Typography>
            </Box>
            <SubscriptionCard />
          </Grid>
        </Box>
      </Container>
    </Layout>
  );
};
